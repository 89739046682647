import { FeatureItem } from '@paid-ui/enums/feature';
import { isEmpty } from 'lodash';
import { proxy } from 'valtio';
import { devtools, subscribeKey } from 'valtio/utils';

import { type Feature } from './app-config';
import { userManager } from './user';

const featureNameMap = {
  [FeatureItem.KYC_KYB]: 'enableKycKyb',
  [FeatureItem.USER_CONSENT]: 'enableUserConsent',
  [FeatureItem.USER_MOBILE_VERIFICATION]: 'enableMobileVerification',
  [FeatureItem.WATCHMAN]: 'enableWatchman',
  [FeatureItem.ON_PLATFORM_RETENTION]: 'enableOnPlatformRetention',
  [FeatureItem.CARD_PAYMENT_RBN]: 'enableCardPaymentForRBN',
  [FeatureItem.CARD_PAYMENT_PAID_SECONDARY_CONTRACTS]: 'enableCardPaymentForSecondary',
  [FeatureItem.CARD_PAYMENT_SUPER_CONTRACTS]: 'enableCardPaymentForSuperContract',
  [FeatureItem.CONTRACT_FORM_NAVIGATOR]: 'enableFormDevTools',
} as const;

const initialState = {
  enableKycKyb: false,
  enableUserConsent: false,
  enableMobileVerification: false,
  enableWatchman: false,
  enableOnPlatformRetention: false,
  enableCardPaymentForRBN: false,
  enableCardPaymentForSecondary: false,
  enableCardPaymentForSuperContract: false,
  enableFormDevTools: false,
  features: {} as Partial<Record<FeatureItem, Feature>>,
};

export const featureManager = proxy(initialState);

devtools(featureManager, {
  name: 'Features',
  enabled: false,
});

export const saveDefaultFeatures = (features: Partial<Record<FeatureItem, Feature>>) => {
  const featureToggles = Object.values(features).reduce(
    (result, feature) => {
      if (featureNameMap[feature.name]) {
        result[featureNameMap[feature.name]] = feature.userSubscribable ? false : feature.active;
      }
      return result;
    },
    {} as typeof initialState,
  );

  const updatedFeatures = Object.fromEntries(
    Object.entries(features).map(([name, feature]) => [
      name,
      {
        ...feature,
        subscribed: feature.userSubscribable ? false : feature.active,
      },
    ]),
  );

  Object.assign(featureManager, {
    ...featureToggles,
    features: updatedFeatures,
  });
};

subscribeKey(userManager, 'features', (userFeatures) => {
  const { features } = featureManager;
  if (isEmpty(features)) return;

  const featureToggles = Object.values(features).reduce(
    (result, feature) => {
      if (featureNameMap[feature.name]) {
        result[featureNameMap[feature.name]] = feature.userSubscribable
          ? userFeatures.includes(feature.name)
          : feature.active;
      }
      return result;
    },
    {} as typeof initialState,
  );

  const updatedFeatures = Object.fromEntries(
    Object.entries(features).map(([name, feature]) => [
      name,
      {
        ...feature,
        subscribed: feature.userSubscribable ? userFeatures.includes(feature.name) : feature.active,
      },
    ]),
  );

  Object.assign(featureManager, {
    ...featureToggles,
    features: updatedFeatures,
  });
});

export enum CompanyDirectorsStructure {
  SOLE_DIRECTOR = 'SOLE_DIRECTOR',
  MULTI_DIRECTORS = 'MULTI_DIRECTORS',
}

export enum UserStatus {
  UNVERIFIED = 'UNVERIFIED',
  LIVE = 'LIVE',
}

export enum UserGroupRelation {
  TRUSTEE = 'TRUSTEE',
  OFFICER = 'OFFICER',
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
  TRUST_OWNER = 'TRUST_OWNER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum UserGroupType {
  BUSINESS = 'BUSINESS',
  INDIVIDUALS = 'INDIVIDUALS',
}

export enum UserGroupRoleName {
  EXECUTOR = 'EXECUTOR',
  PAYMENT_AUTHORISER = 'PAYMENT_AUTHORISER',
  GROUP_ADMIN = 'GROUP_ADMIN',
  PRIME_CONTRACT_ADMIN = 'PRIME_CONTRACT_ADMIN',
  SECONDARY_CONTRACT_ADMIN = 'SECONDARY_CONTRACT_ADMIN',
  SERVICE_CONTRACT_ADMIN = 'SERVICE_CONTRACT_ADMIN',
  EMPLOYEE = 'EMPLOYEE',
}

export enum PermissionName {
  MANAGE_SIGNING = 'MANAGE_SIGNING',
  VIEW_CONTRACT = 'VIEW_CONTRACT',
  MANAGE_CONTRACT = 'MANAGE_CONTRACT',
  MANAGE_PAYMENT = 'MANAGE_PAYMENT',
  MANAGE_USER = 'MANAGE_USER',
}

export enum PermissionType {
  USERS = 'USERS',
  SIGNING = 'SIGNING',
  FINANCIAL = 'FINANCIAL',
  CONTRACT_PRIME = 'CONTRACT_PRIME',
  CONTRACT_SECONDARY = 'CONTRACT_SECONDARY',
  CONTRACT_SERVICE = 'CONTRACT_SERVICE',
  VARIATION = 'VARIATION',
  ADJUSTMENT = 'ADJUSTMENT',
  CLAIM = 'CLAIM',
}
